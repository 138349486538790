import React, { useState } from "react";
import { Button, TextField, CircularProgress } from "@mui/material";
import OpeningScroll from "../../components/OpeningScroll/OpeningScroll";
import logo from "../../img/hartford.png";
import "./Home.css";
import Breaker from "../../components/Breaker/Breaker";
import { Select, MenuItem } from "@mui/material";
import useData from "../../hooks/use";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function Home() {
  const { state, actions } = useData();

  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLang = (e: any) => {
    actions.setLanguage(e.target.value);
  };

  const headerValue = {
    English: "Welcome to Hartford Help!",
    Spanish: "Bienvenido a Ayuda de Hartford!",
    Italian: "Benvenuto nell'Aiuto di Hartford!",
    Polish: "Witamy w Hartford Help!",
  };

  const subHeaderValue = {
    English: "Select a lanuage to start.",
    Spanish: "Seleccione un idioma para comenzar.",
    Italian: "Seleziona una lingua per iniziare.",
    Polish: "Aby rozpocząć, wybierz język.",
  };

  const buttonValue = {
    English: "START",
    Spanish: "Comenzar",
    Italian: "Inizio",
    Polish: "Początek",
  };

  const usernameValue = {
    English: "Username",
    Spanish: "Nombre de usuario",
    Italian: "Nome utente",
    Polish: "Nazwa użytkownika",
  };

  const passwordValue = {
    English: "Password",
    Spanish: "Contraseña",
    Italian: "Password",
    Polish: "Hasło",
  };

  const fatalErrorText = {
    English: "Something went wrong. Please try again.",
    Spanish: "Algo salió mal. Por favor inténtalo de nuevo.",
    Polish: "Coś poszło nie tak. Spróbuj ponownie.",
    Italian: "Qualcosa è andato storto. Per favore riprova.",
  };

  const handleLogin = async () => {
    setLoading(true);
    setError("");

    try {
      actions.setAuthToken("123");
      actions.setUserId("u1");
      navigate("/"); // Redirect to the home page
      //   const response = await fetch("https://your-api-endpoint/login", {
      //     method: "POST",
      //     headers: { "Content-Type": "application/json" },
      //     body: JSON.stringify({ username, password }),
      //   });

      //   if (response.status === 200) {
      //     const data = await response.json();
      //     actions.setAuthToken(data.token); // Save token to global state
      //     // actions.setAuthenticated(true); // Set authenticated state
      //     navigate("/"); // Redirect to the home page
      //   } else {
      //     setError(fatalErrorText[state.language]);
      //   }
    } catch (err) {
      setError(fatalErrorText[state.language]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <motion.div
        initial={{ scale: 0 }}
        animate={{ rotate: 360, scale: 1 }}
        transition={{
          type: "spring",
          stiffness: 240,
          damping: 15,
        }}
      >
        <img className="hartford-logo" src={logo} />
      </motion.div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          ease: "easeOut",
          duration: 1.55,
          delay: 0.25,
        }}
      >
        <OpeningScroll />
      </motion.div>
      <Breaker />
      <motion.div
        initial={{ opacity: 0, scale: 0.1 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          ease: "easeOut",
          duration: 0.3,
          delay: 0.1,
        }}
      >
        <br></br>
        <div>
          <Select
            sx={{ width: "70%", maxWidth: "200px" }}
            value={state.language}
            onChange={handleLang}
            size="small"
          >
            <MenuItem value={"English"}>🇺🇸 English</MenuItem>
            <MenuItem value={"Spanish"}>🇪🇸 Spanish</MenuItem>
            <MenuItem value={"Italian"}>🇮🇹 Italian</MenuItem>
            <MenuItem value={"Polish"}>🇵🇱 Polish</MenuItem>
          </Select>
        </div>
        {/* <br></br> */}
        <div>
          <p className="home-title">{headerValue[state.language]}</p>
          {/* <p>{subHeaderValue[state.language]}</p> */}
        </div>
        {error && (
          <p style={{ color: "red" }}>{fatalErrorText[state.language]}</p>
        )}

        {loading ? (
          <CircularProgress size={160} />
        ) : (
          <>
            <TextField
              sx={{ width: "70%", maxWidth: "400px" }}
              size="small"
              label={usernameValue[state.language]}
              variant="filled"
            />

            <br></br>
            <br></br>

            <TextField
              sx={{ width: "70%", maxWidth: "400px" }}
              size="small"
              label={passwordValue[state.language]}
              variant="filled"
            />
          </>
        )}

        <br></br>
        {/* <br></br> */}
        {/* <br></br> */}
        <br></br>
        <div>
          <Button
            onClick={() => {
              handleLogin();
              // actions.addHistory("/help");
              // navigate("/help");
            }}
            sx={{ width: "70%", maxWidth: "400px" }}
            variant="contained"
          >
            {buttonValue[state.language]}
          </Button>
        </div>
      </motion.div>
    </div>
  );
}

export default Home;
