import React from "react";
import "./Header.css";
import MenuIcon from "@mui/icons-material/Menu";
import MoveToInbox from "@mui/icons-material/MoveToInbox";
import Mail from "@mui/icons-material/Mail";
import NightShelterIcon from "@mui/icons-material/NightShelter";
import PaidIcon from "@mui/icons-material/Paid";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import SchoolIcon from "@mui/icons-material/School";
import WorkIcon from "@mui/icons-material/Work";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import TranslateIcon from "@mui/icons-material/Translate";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import {
  Box,
  Drawer,
  Button,
  List,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Select,
  MenuItem,
} from "@mui/material";
import useData from "../../hooks/use";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import HomeIcon from "@mui/icons-material/Home";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";

function Header() {
  const navigate = useNavigate();
  const { state, actions } = useData();
  const toggleDrawer = () => {
    actions.setHeaderOpen(!state.headerOpen);
  };
  const handleLang = (e: any) => {
    actions.setLanguage(e.target.value);
  };

  const homeOptions = {
    English: "Shelter",
    Spanish: "Refugio",
    Italian: "Riparo",
    Polish: "Schronienie",
  };
  const foodOptions = {
    English: "Food",
    Spanish: "Comida",
    Italian: "Cibo",
    Polish: "Żywność",
  };
  const healthOptions = {
    English: "Health",
    Spanish: "Salud",
    Italian: "Salute",
    Polish: "Zdrowie",
  };
  const educationOptions = {
    English: "Education",
    Spanish: "Educación",
    Italian: "Scolastica",
    Polish: "Edukacja",
  };
  const employmentOptions = {
    English: "Employment",
    Spanish: "Empleo",
    Italian: "Occupazione",
    Polish: "Zatrudnienie",
  };
  const callOptions = {
    English: "Chats",
    Spanish: "Conversaciones",
    Italian: "Conversazioni",
    Polish: "Rozmowy",
  };
  const translateOptions = {
    English: "Translate",
    Spanish: "Traducir",
    Italian: "Tradurre",
    Polish: "Tłumaczyć",
  };
  const donateOptions = {
    English: "Donate",
    Spanish: "Donar",
    Italian: "Donare",
    Polish: "Podarować",
  };
  const volunteerOptions = {
    English: "Volunteer",
    Spanish: "Voluntario",
    Italian: "Volontario",
    Polish: "Wolontariusz",
  };

  const handleCall = () => {
    // window.location.href = `tel:411`;
    navigate("/");
  };

  return (
    <>
      <div className="header-frame">
        <MenuIcon
          onClick={toggleDrawer}
          sx={{
            color: "white",
            padding: "8px",
            fontSize: "32px",
            cursor: "pointer",
          }}
        />
        <ChatBubbleIcon
          onClick={() => {
            actions.addHistory("/");
            navigate("/");
          }}
          sx={{
            color: "white",
            padding: "12px",
            fontSize: "26px",
            cursor: "pointer",
          }}
        />
        <div className="header-right">
          <KeyboardArrowLeftIcon
            onClick={() => {
              actions.historyChange("back");
            }}
            sx={{
              color: "white",
              padding: "8px",
              fontSize: "32px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          />
          <KeyboardArrowRightIcon
            onClick={() => {
              actions.historyChange("forward");
            }}
            sx={{
              color: "white",
              padding: "8px",
              fontSize: "32px",
              marginRight: "10px",
              cursor: "pointer",
            }}
          />
        </div>
        <Drawer
          open={state.headerOpen}
          ModalProps={{ onBackdropClick: toggleDrawer }}
        >
          <Box sx={{ width: 250 }} role="presentation">
            <List>
              <ListItem key={"call"} onClick={handleCall} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ChatBubbleIcon />
                  </ListItemIcon>
                  <ListItemText primary={callOptions[state.language]} />
                </ListItemButton>
              </ListItem>
              <ListItem key={"text"} disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <GTranslateIcon />
                  </ListItemIcon>
                  <Select value={state.language} onChange={handleLang}>
                    <MenuItem value={"English"}>English</MenuItem>
                    <MenuItem value={"Spanish"}>Spanish</MenuItem>
                    <MenuItem value={"Italian"}>Italian</MenuItem>
                    <MenuItem value={"Polish"}>Polish</MenuItem>
                  </Select>
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              <ListItem
                key={"shelter"}
                onClick={() => {
                  actions.addHistory("/shelter");
                  navigate("/shelter");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <NightShelterIcon />
                  </ListItemIcon>
                  <ListItemText primary={homeOptions[state.language]} />
                </ListItemButton>
              </ListItem>

              <ListItem
                key={"food"}
                onClick={() => {
                  actions.addHistory("/food");
                  navigate("/food");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <FastfoodIcon />
                  </ListItemIcon>
                  <ListItemText primary={foodOptions[state.language]} />
                </ListItemButton>
              </ListItem>

              <ListItem
                key={"health"}
                onClick={() => {
                  actions.addHistory("/health");
                  navigate("/health");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <HealthAndSafetyIcon />
                  </ListItemIcon>
                  <ListItemText primary={healthOptions[state.language]} />
                </ListItemButton>
              </ListItem>

              <ListItem
                key={"education"}
                onClick={() => {
                  actions.addHistory("/education");
                  navigate("/education");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText primary={educationOptions[state.language]} />
                </ListItemButton>
              </ListItem>

              <ListItem
                key={"employment"}
                onClick={() => {
                  actions.addHistory("/employment");
                  navigate("/employment");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <WorkIcon />
                  </ListItemIcon>
                  <ListItemText primary={employmentOptions[state.language]} />
                </ListItemButton>
              </ListItem>
            </List>
            <Divider />
            <List>
              {/*             
        <ListItem key={"translate"} onClick={()=>{actions.addHistory("/translate");navigate("/translate"); toggleDrawer(); window.scrollTo({top: 0,behavior: 'smooth'})}} disablePadding>
                <ListItemButton>
                <ListItemIcon>
                <TranslateIcon/>
                </ListItemIcon>
                <ListItemText primary={translateOptions[state.language]} />
                </ListItemButton>
            </ListItem> */}

              <ListItem
                key={"donate"}
                onClick={() => {
                  actions.addHistory("/donate");
                  navigate("/donate");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <PaidIcon />
                  </ListItemIcon>
                  <ListItemText primary={donateOptions[state.language]} />
                </ListItemButton>
              </ListItem>
              <ListItem
                key={"volunteer"}
                onClick={() => {
                  actions.addHistory("/volunteer");
                  navigate("/volunteer");
                  toggleDrawer();
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <VolunteerActivismIcon />
                  </ListItemIcon>
                  <ListItemText primary={volunteerOptions[state.language]} />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      </div>
      <div className="header-spacer"></div>
    </>
  );
}

export default Header;
