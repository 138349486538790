import React, {
  useState,
  useEffect,
  useRef,
  Dispatch,
  SetStateAction,
} from "react";
import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useData from "../../../../hooks/use";
import MarkdownRenderer from "../MarkdownRenderer/MarkdownRenderer";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import "./AiModel.css";

type AiModalProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  text: string;
  setText: Dispatch<SetStateAction<string>>;
};

function AiModel({ open, setOpen, text, setText }: AiModalProps) {
  //   const [open, setOpen] = useState(false);
  const { state, actions } = useData();

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = useRef<HTMLElement>(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const closeText = {
    English: "Close",
    Spanish: "Cerca",
    Italian: "Vicino",
    Polish: "Zamknąć",
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        scroll={"paper"}
        maxWidth={false}
        sx={{ marginTop: "80px", marginBottom: "80px", width: "100%" }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <>
          <div className="ai-modal-frame">
            <br></br>
            <DialogTitle id="scroll-dialog-title">
              <AutoAwesomeIcon />
              <span className="ai-modal-title">AI</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="scroll-dialog-description"
                // sx={{ whiteSpace: "break-spaces" }}
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {/* <p>{text}</p> */}
                <MarkdownRenderer text={text} />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>{closeText[state.language]}</Button>
            </DialogActions>
          </div>
        </>
      </Dialog>
    </>
  );
}

export default AiModel;
